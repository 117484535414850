#article .table {
  margin: 2rem 0;
}

#article .table thead th {
  background-color: #b51a29;
  color: #fff;
  border: none;
}


#article p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

#article p#article-date {
  font-size: 1rem;
  margin-bottom: 1rem;
}

#article h2, #article h3, #article h4 {
  font-size: 1.25rem;
  font-family: NovelPro;
  font-weight: 600;
  margin-bottom: 2rem;
}

#article .table td {
  border: 3px solid #fff;
}

#article-bg-image {
  height: 350px;
  background-size: cover
}

#article .table tr:last-of-type td {
  border-bottom: 3px solid #b51a29;
}

#article ul {
  list-style-type: none;
  padding: 0;
}

#article ol {
  padding: 0;
  list-style-type: none;
}

#article ol li {
  margin-bottom: 1rem;
  counter-increment: li;
  font-size: 1.25rem;
}

#article ol li::before {
  content: counter(li) '.';
  color: var(--primary);
  display: inline-block;
  width: 2rem;
  margin-left: 0;
}

#article ul li {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

#article ul li::after:not(.document-list) {
  content: '';
  height: 10px;
  width: 25px;
  background-image: url(../../img/falka-red.svg);
  background-repeat: no-repeat;
  top: .65rem;
  left: 0;
  position: absolute;
}

#article blockquote {
  font-style: italic;
  font-size: 1.25rem;
  margin-bottom: 2rem;
  position: relative;
}

#article blockquote::after {
  content: '';
  width: 119px;
  height: 82px;
  background-image: url(../../img/quote.png);
  background-repeat: no-repeat;
  top: -1rem;
  left: -1rem;
  position: absolute;
  z-index: -1;
}

#article img {
  width: 100%;
  height: auto;
  display: block;
}
